import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'

interface propsDownload {
    pdfId:any,
    name:any,
    w?:any,
    h?:any
}

const initialState:propsDownload = {
    pdfId: null,
    name: null,
    w: '300px',
    h: '400px'
}

const Download = (props = initialState) => {
    DownloadPDF(props.pdfId, props.name,props.w,props.h)

    return (
        <>
            <div id={'download' + props.pdfId}></div>
        </>
    )
}

export default Download

const DownloadPDF = (pdfID: any, name: any,w:any = '300px',h:any = '400px') => {
    let api_URL
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        api_URL = process.env.REACT_APP_API_URL_LOCAL
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        api_URL = process.env.REACT_APP_API_URL_LIVE
    } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
        api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD
    }
    let token = localStorage.getItem('token')
    fetch(api_URL + 'Media/' + pdfID + '/', {
        method: 'GET',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => response.blob())
        .then((blob) => {
            var url = window.URL.createObjectURL(blob)
            var object = document.createElement('object')
            object.data = url
            object.ariaLabel = name
            object.width = w
            object.height = h
            let div = document.getElementById(
                'download' + pdfID
            ) as HTMLDivElement
            if (div !== null) {
                if (!div.hasChildNodes()) {
                    div.appendChild(object)
                }
            }
        })
}

export const buildDownload = (media: any) => {
    let mediaHolder = []
    for (let m = 0; m < media.length; m++) {
        if (media[m].media !== undefined && media[m].media !== null) {
            mediaHolder.push(
                <>
                    <Col sm={2} md={2} xs={2}>
                        <Download
                            pdfId={media[m].media.id}
                            name={media[m].media.name}
                        />
                    </Col>
                </>
            )
        }
    }

    return (
        <>
            {' '}
            <Row>
                <h3>Dokumente</h3>
                {mediaHolder}
                <br />
            </Row>
        </>
    )
}

export const buildDoc = (media: any) => {
    let mediaHolder = []



    for (let m = 0; m < media.length; m++) {
        if (media[m].media !== undefined && media[m].media !== null) {

            let type;

            switch (media[m].docType) {
                case 'privacy':
                    type = 'Datenschutz'
                    break;
                case 'service':
                    type = 'Dienstleistungauftrag'
                    break;
                case 'perso':
                    type = 'Ausweisdokument (Personalausweis/Reisepass)'
                    break;
                default:
                    type = ''
            }

            if(media[m].media.content_type == null || media[m].media.cdn_url === null || media[m].media.name === null) {
                mediaHolder.push(
                    <>
                        <Col className={'col-6'}>
                            <Card style={{ width: '400', height: '550' }}>
                                <Card.Body>
                                    <Card.Title>Nicht vorhanden</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        Hinterlegtes Dokument
                                    </Card.Subtitle>
                                    <Card.Text className={'text-center'}>

                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">
                                        Type: undefined
                                    </small>
                                </Card.Footer>
                            </Card>
                            <br />
                        </Col>
                    </>
                )
            } else {
                mediaHolder.push(
                    <>
                        <Col className={'col-6'}>
                            <Card style={{ width: '400', height: '550' }}>
                                <Card.Body>
                                    <Card.Title>{type}<br/>{media[m].media.name}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        Hinterlegtes Dokument
                                    </Card.Subtitle>
                                    <Card.Text className={'text-center'}>
                                        {media[m].media.content_type.includes(
                                            'image'
                                        ) ? (
                                            <Image
                                                src={media[m].media.cdn_url}
                                                style={{ width: 'fit-content' }}
                                            />
                                        ) : (
                                            <object
                                                data={media[m].media.cdn_url}
                                                width={'300px'}
                                                height={'400px'}
                                                aria-label={'pdf'}
                                                className={'mb-3'}
                                            />
                                        )}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <small className="text-muted">
                                        Type: {media[m].media.content_type}
                                    </small>
                                </Card.Footer>
                            </Card>
                            <br />
                        </Col>
                    </>
                )
            }


        }
    }
    return (
        <>
            {' '}
            <Row>
                {mediaHolder}
                <br />
            </Row>
        </>
    )
}
