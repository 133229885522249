import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";


const CreateBlock = (props:{title?:any,number?:any,icon?:any,border?:any}) => {

    let ret = (<>
        <Card style={{ background:'white',width: '100%'}}>
            <Card.Body>
                <Row style={{paddingLeft:'10px'}}>
                    <Col xxl={2} xl={2} md={2} sm={2} style={{background: '#E5F2FD',color:'White',borderRadius: '15px',width:'40px',height:'40px',marginTop: '10px'}}>
                                <span className="material-icons"
                                      style={{paddingTop: '7px',marginLeft: '-3px',color:'#2EB0D9'}}>
                                    {props.icon !== undefined ? props.icon : 'done'}
                                 </span>
                    </Col>
                    <Col>
                        <Card.Title>{props.title !== undefined ? props.title : 'Open Projects'}</Card.Title>
                        <Card.Text>
                            <strong>{props.number !== undefined ? props.number : '0'}</strong>
                        </Card.Text>
                    </Col>
                </Row>

            </Card.Body>
        </Card>


    </>)
        if(props.border !== undefined) {
            ret = <>
                <Card style={{ background:'white',width: '100%', border: props.border }}>
                    <Card.Body>
                        <Row style={{paddingLeft:'10px'}}>
                            <Col xxl={2} xl={2} md={2} sm={2} style={{background: '#E5F2FD',color:'White',borderRadius: '15px',width:'40px',height:'40px',marginTop: '10px'}}>
                                <span className="material-icons"
                                      style={{paddingTop: '7px',marginLeft: '-3px',color:'#2EB0D9'}}>
                                    {props.icon !== undefined ? props.icon : 'done'}
                                 </span>
                            </Col>
                            <Col>
                                <Card.Title>{props.title !== undefined ? props.title : 'Open Projects'}</Card.Title>
                                <Card.Text>
                                    <strong>{props.number !== undefined ? props.number : '0'}</strong>
                                </Card.Text>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>


            </>
        }


    return(<>
        <Col>
        {ret}
            </Col>
        </>
    )

}

export default CreateBlock;
